import { validate } from './validation/config'
// import { configMock } from './customer configs/bestpellets'
// import { configMock } from './customer configs/hamburglifte'
import { demoConfig } from './customer configs/demo'
import joi from 'joi'

declare global {
  interface Window {
    cfa: IUserConfig
  }
}

// Remove `mock` when data is moved to html file script tag.
// mock in read() determines which config should be build
const read = (): Readonly<IUserConfig> =>
  Object.freeze((window && window.cfa) || demoConfig)

export const validatedConfig: Readonly<joi.ValidationResult> = validate(read())
export const validatedDemoConfig: Readonly<joi.ValidationResult> = validate(
  demoConfig
)
