// Side Effects
import App from './components/App.vue'
import Vue from 'vue'

import { store } from './store'

export const runApp = (): Vue =>
  new Vue({
    render: createElement => createElement(App),
    store
  }).$mount('#msf-test')
  // }).$mount('#msf-bestpellets')
  // }).$mount('#cff-hamburglifte')

runApp()
